import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChild, Router} from '@angular/router';
import {AuthService} from 'src/app/core/services/auth.service';
import {DatabaseService} from '../services/database.service';
import {CaseFilesService} from '../services/case-files.service';
import {filter, map, Observable, of, switchMap} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {

    constructor(private authService: AuthService, private router: Router, private databaseService: DatabaseService, private caseFileService: CaseFilesService) {}

    canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
        if (!this.authService.validateToken()) {
            this.router.navigate(['/select-user']);
            return of(false);
        } else {
            return this.databaseService.dbReady.pipe(
                filter(isReady => isReady),
                switchMap(() => {
                    const caseFileId = route.paramMap.get('caseFileId') ?? '';
                    const accountID = this.authService.getLoginId();
                    return this.caseFileService.getOne(caseFileId).pipe(
                        map(caseFile => {
                            if(caseFile !== null){
                                if (caseFile.createdBy !== accountID) {
                                    this.router.navigate(['/select-user']);
                                    return false;
                                } else {
                                    return true;
                                }
                            } else {
                                return true;
                            }
                        })
                    );
                })
            );
        }
    }

}
