import {Action, Selector, State, StateContext} from "@ngxs/store";
import {Account} from "../../models/account";
import {RemoteState} from "../remote-state";
import {Injectable} from "@angular/core";
import {AccountService} from "../../services/account.service";
import {AccountListActions} from "./account-list.actions";
import {tap} from "rxjs";

export interface AccountListStateModel {
    accountList: RemoteState<Account[]>;
    accountSelected: RemoteState<Account>;
    accountCreated: RemoteState<Account>;
    accountDeleted: RemoteState<Boolean>;
    accountUpdated: RemoteState<Account>;
}

@State<AccountListStateModel>({
    name: 'accountList',
    defaults: {
        accountList: RemoteState.new<Account[]>([]),
        accountSelected: RemoteState.new<Account>(),
        accountCreated: RemoteState.new<Account>(),
        accountDeleted: RemoteState.new<Boolean>(),
        accountUpdated: RemoteState.new<Account>()
    }
})
@Injectable()
export class AccountListState {
    @Selector()
    static AccountListState(state: AccountListStateModel) {
        return state.accountList;
    }

    @Selector()
    static AccountSelectedState(state: AccountListStateModel) {
        return state.accountSelected;
    }

    @Selector()
    static AccountCreatedState(state: AccountListStateModel) {
        return state.accountCreated;
    }

    @Selector()
    static AccountDeletedState(state: AccountListStateModel) {
        return state.accountDeleted;
    }

    @Selector()
    static AccountUpdatedState(state: AccountListStateModel) {
        return state.accountUpdated;
    }

    constructor(
        private accountService: AccountService
    ) {}

    @Action(AccountListActions.GetAll)
    getAll(ctx: StateContext<AccountListStateModel>, action: AccountListActions.GetAll) {
        const state = ctx.getState();
        const AccountListRemoteState = state.accountList.reset().start();

        return this.accountService.getAll().pipe(
            tap((accounts: Account[]) => {
                ctx.patchState({
                    accountList: AccountListRemoteState.complete(accounts)
                });
            })
        );
    }

    @Action(AccountListActions.GetOne)
    getOne(ctx: StateContext<AccountListStateModel>, action: AccountListActions.GetOne) {
        const state = ctx.getState();
        const AccountSelectedRemoteState = state.accountSelected.reset().start();

        return this.accountService.getOne(action.loginId).pipe(
            tap((account: Account) => { // Update the type of the parameter to Account
                ctx.patchState({
                    accountSelected: AccountSelectedRemoteState.complete(account)
                });
            })
        );
    }

    @Action(AccountListActions.Create)
    create(ctx: StateContext<AccountListStateModel>, action: AccountListActions.Create) {
        const state = ctx.getState();
        const AccountCreatedRemoteState = state.accountCreated.reset().start();

        return this.accountService.createOne(action.account).pipe(
            tap((account: Account) => {
                ctx.patchState({
                    accountList: state.accountList.mutate((oldState) => {
                        oldState.push(account);
                        return oldState;
                    }),
                    accountCreated: AccountCreatedRemoteState.complete(account)
                });
            })
        );
    }

    @Action(AccountListActions.Delete)
    delete(ctx: StateContext<AccountListStateModel>, action: AccountListActions.Delete) {
        const state = ctx.getState();
        const AccountDeletedRemoteState = state.accountDeleted.reset().start();

        return this.accountService.deleteOne(action.loginId).pipe(
            tap((success: boolean) => {
                if (success) {
                    ctx.patchState({
                        accountList: state.accountList.mutate((oldState) => {
                            return oldState.filter((account) => account.login !== action.loginId);
                        }),
                        accountDeleted: AccountDeletedRemoteState.complete(success)
                    });
                } else {
                    console.error('Failed to delete account');
                }
            })
        );
    }

    @Action(AccountListActions.Update)
    update(ctx: StateContext<AccountListStateModel>, action: AccountListActions.Update) {
        const state = ctx.getState();
        const AccountUpdatedRemoteState = state.accountUpdated.reset().start();

        return this.accountService.updateAccount(action.id, action.name, action.occupation, action.profileImage).pipe(
            tap((account: Account) => {
                ctx.patchState({
                    accountList: state.accountList.mutate((oldState) => {
                        return oldState.map((oldAccount) => {
                            if (oldAccount.id === account.id) {
                                return { ...oldAccount, login: action.name, occupation: action.occupation, profileImage: action.profileImage};
                            } else {
                                return oldAccount;
                            }
                        });
                    }),
                    accountUpdated: AccountUpdatedRemoteState.complete(account)
                });
            })
        );
    }
}
