import {Injectable} from '@angular/core';
import {ReportType} from '../models/report';
import {DatabaseService} from './database.service';
import {from, Observable, of} from 'rxjs';
import {CapacitorSQLite, capSQLiteValues} from '@capacitor-community/sqlite';

@Injectable({
  providedIn: 'root'
})
export class ReportTypeService {

  constructor(private databaseService: DatabaseService) {
  }

  fetchReportTypesById(institutionid: string): Observable<ReportType[]> {
    if (this.databaseService.dbReady) {
      const query = `SELECT *
                     FROM ReportType
                     WHERE institutionId = ?`;
      return from(
        CapacitorSQLite.query({
          database: this.databaseService.getDBName(),
          statement: query,
          values: [institutionid],
        })
          .then((result: capSQLiteValues) => {
            // If it's iOS and there are values, remove the first row
            // Because the first row is table information
            if (result.values && this.databaseService.isIos && result.values.length > 0) {
              result.values.shift();
            }
            return this.mapResultToReportType(result);
          })
          .catch((error) => {
            console.error('Error fetching all report types:', error);
            return [];
          })
      );
    } else {
      console.error('Database is not ready');
      return of([]);
    }
  }


  private mapResultToReportType(result: any): ReportType[] {
    const reportTypes: ReportType[] = [];
    if (result.values && result.values.length > 0) {
      for (let i = 0; i < result.values.length; i++) {
        reportTypes.push({
          id: result.values[i].id,
          name: result.values[i].name,
          value: result.values[i].value,
          institutionId: result.values[i].institutionId,
        });
        //console.log('reportType: ', reportTypes[i]);
      }
    }
    return reportTypes;
  }

  getAll(): Observable<ReportType[]> {
    if (this.databaseService.dbReady) {
      const query = 'SELECT * FROM ReportType';
      return from(
        CapacitorSQLite.query({
          database: this.databaseService.getDBName(),
          statement: query,
          values: [],
        })
          .then((result: capSQLiteValues) => {
            if (result.values && this.databaseService.isIos && result.values.length > 0) {
              result.values.shift();
            }
            return this.mapResultToReportType(result);
          })
          .catch((error) => {
            console.error('Error fetching all report types:', error);
            return [];
          })
      );
    } else {
      console.error('Database is not ready');
      return of([]);
    }
  }

  getOne(id: string): Observable<ReportType | null> {
    if (this.databaseService.dbReady) {
      const query = 'SELECT * FROM ReportType WHERE id = ?';
      return from(
        CapacitorSQLite.query({
          database: this.databaseService.getDBName(),
          statement: query,
          values: [id],
        })
          .then((result: capSQLiteValues) => {
            if (result.values && this.databaseService.isIos && result.values.length > 0) {
              result.values.shift();
            }
            return this.mapResultToReportType(result)[0];
          })
          .catch((error) => {
            console.error('Error fetching one report type:', error);
            return null;
          })
      );
    } else {
      console.error('Database is not ready');
      return of(null);
    }
  }

}
