import {Injectable} from '@angular/core';
import {AuthToken} from '../models/authToken';
import {Observable, of} from 'rxjs';
import {Account} from '../models/account';
import {SHA256} from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  /**
   * Authenticates the user with the provided account and password.
   * If the password matches the account's password, it generates an authentication token and stores it in the local storage.
   * The authentication token is valid for 1 hour.
   * @param account - The user account to authenticate.
   * @param inputPassword - The password entered by the user.
   * @returns An observable that emits the authentication token if the credentials are valid, or null otherwise.
   */
  login(account: Account, inputPassword: string): Observable<AuthToken | null> {
    const encryptedPass = SHA256(inputPassword).toString();
    if (encryptedPass === account.pass) {
      // Generate AuthToken
      const token: AuthToken = {
        loginId: account.id,
        // valid for 1 hour
        expireAt: new Date(Date.now() + 60 * 60 * 1000).toISOString()
      };
      localStorage.setItem('authToken', JSON.stringify(token));
      return of(token);
    } else {
      console.log('Invalid credentials');
      return of(null);
    }
  }

  /**
   * Removes the authentication token from the local storage.
   */
  logout(): void {
    localStorage.removeItem('authToken');
  }

  /**
   * Validates the authentication token.
   * @returns {boolean} True if the token is valid, false otherwise.
   */
  validateToken(): boolean {
    const tokenString = localStorage.getItem('authToken');
    if (!tokenString) {
      return false;
    }
    const token: AuthToken = JSON.parse(tokenString);
    const now = new Date();
    const expireAt = token.expireAt ? new Date(token.expireAt) : null;
    const isValid = expireAt !== null && now < expireAt;

    if (!isValid) {
      this.logout();
    }

    return isValid;
  }

  /**
   * Retrieves the login ID from the authentication token.
   * @returns {string} The login ID.
   */
  getLoginId(): string {
    const tokenString = localStorage.getItem('authToken');
    if (!tokenString) {
      return '';
    }
    const token: AuthToken = JSON.parse(tokenString);
    return token.loginId as string;
  }

}
