import {Injectable} from '@angular/core';
import {DatabaseService} from './database.service';
import {Account} from '../models/account';
import {catchError, from, map, Observable, of, switchMap} from 'rxjs';
import {CapacitorSQLite, capSQLiteValues} from '@capacitor-community/sqlite';
import {SHA256} from "crypto-js";

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private dbService: DatabaseService) { }

  getAll(): Observable<Account[]> {
    if (this.dbService.dbReady) {
      const query = 'SELECT * FROM Account';
      return from(
        CapacitorSQLite.query({
          database: this.dbService.getDBName(),
          statement: query,
          values: [], // Necessary for Android
        })
          .then((result: capSQLiteValues) => {
            // If it's iOS and there are values, remove the first row
            // Because the first row is table information
            if (result.values && this.dbService.isIos && result.values.length > 0) {
              result.values.shift();
            }
            return this.mapResultToAccounts(result);
          })
          .catch((error) => {
            console.error('Error fetching all accounts:', error);
            return [];
          })
      );
    } else {
      console.error('Database is not ready');
      return of([]);
    }
  }

  getOne(loginId: string): Observable<Account | null> {
    if (this.dbService.dbReady) {
      const query = `SELECT * FROM Account WHERE id = ?`;
      return from(
        CapacitorSQLite.query({
          database: this.dbService.getDBName(),
          statement: query,
          values: [loginId],
        })
          .then((result: capSQLiteValues) => {
            if (result.values.length > 0) {
              return this.mapResultToAccounts(result)[0];
            } else {
              return null;
            }
          })
          .catch((error) => {
            console.error('Error fetching account:', error);
            return null;
          })
      );
    } else {
      console.error('Database is not ready');
      return of(null);
    }
  }

  private mapResultToAccounts(result: any): Account[] {
    const accounts: Account[] = [];
    if (result.values){
      for (let i = 0; i < result.values.length; i++) {
        accounts.push({
          id: result.values[i].id,
          login: result.values[i].login,
          pass: result.values[i].pass,              // View if this is a vulnerability
          occupation: result.values[i].occupation,
          profileImage: result.values[i].profileImage,
        });
      }
    }
    return accounts;
  }

  createOne(account: Account): Observable<Account> {
    const encryptedPass = SHA256(account.pass).toString();

    const query = `INSERT INTO Account (id, login, pass, occupation, profileImage) VALUES (?, ?, ?, ?, ?)`;
    const dbName = this.dbService.getDBName();

    return from(
      CapacitorSQLite.query({
        database: dbName,
        statement: query,
        values: [account.id, account.login, encryptedPass, account.occupation, account.profileImage],
      }).then(() => {
        if (this.dbService.isWeb) {
          CapacitorSQLite.saveToStore({database: dbName});
        }
        return account;
      })
    );
  }

  deleteOne(login: string): Observable<boolean> {
    const query = `DELETE FROM Account WHERE login = ?`;
    const dbName = this.dbService.getDBName();

    return from(
      CapacitorSQLite.query({
        database: dbName,
        statement: query,
        values: [login],
      }).then(() => {
        if (this.dbService.isWeb) {
          CapacitorSQLite.saveToStore({database: dbName});
        }
        return true;
      })
    );
  }

  updateAccount(id: string, name: string, occupation: string, profileImage: string): Observable<Account> {
    const query = `UPDATE Account SET login = ?, occupation = ?, profileImage = ? WHERE id = ?`;
    const dbName = this.dbService.getDBName();

    return from (
      CapacitorSQLite.query({
        database: dbName,
        statement: query,
        values: [name, occupation, profileImage, id],
      })).pipe(
        switchMap(() => {
          if (this.dbService.isWeb) {
            CapacitorSQLite.saveToStore({database: dbName});
          }
          return this.getOne(id);
        })
      );
  }

  getAccountName(id: string): Observable<string | null> {
    if (this.dbService.dbReady) {
      return this.getOne(id).pipe(
        map(account => account ? account.login : null),
        catchError(error => {
          console.error('Error fetching account:', error);
          return of(null);
        })
      );
    } else {
      console.error('Database is not ready');
      return of(null);
    }
  }

  getAccountOccupation(id: string): Observable<string | null> {
    if (this.dbService.dbReady) {
      return this.getOne(id).pipe(
        map(account => account ? account.occupation : null),
        catchError(error => {
          console.error('Error fetching account:', error);
          return of(null);
        })
      );
    } else {
      console.error('Database is not ready');
      return of(null);
    }
  }

}
