import {Injectable} from '@angular/core';
import {Report} from '../models/report';
import {DatabaseService} from './database.service';
import {from, Observable, of} from 'rxjs';
import {CapacitorSQLite, capSQLiteValues} from '@capacitor-community/sqlite';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private dbService: DatabaseService) {
  }

  getAll(caseFileId: string): Observable<Report[]> {
    if (this.dbService.dbReady) {
      const query = 'SELECT * FROM Report WHERE caseFileId = ?';
      return from(
        CapacitorSQLite.query({
          database: this.dbService.getDBName(),
          statement: query,
          values: [caseFileId],
        })
          .then((result: capSQLiteValues) => {
            // If it's iOS and there are values, remove the first row
            // Because the first row is table information
            if (result.values && this.dbService.isIos && result.values.length > 0) {
              result.values.shift();
            }
            return this.mapResultToReports(result);
          })
          .catch((error) => {
            console.error('Error fetching all reports:', error);
            return [];
          })
      );
    } else {
      console.error('Database is not ready');
      return of([]);
    }
  }

  getOne(id: string): Observable<Report | null> {
    if (this.dbService.dbReady) {
      const query = 'SELECT * FROM Report WHERE id = ?';
      return from(
        CapacitorSQLite.query({
          database: this.dbService.getDBName(),
          statement: query,
          values: [id],
        })
          .then((result: capSQLiteValues) => {
            if (result.values && this.dbService.isIos && result.values.length > 0) {
              result.values.shift();
            }
            return this.mapResultToReports(result)[0];
          })
          .catch((error) => {
            console.error('Error fetching one report:', error);
            return null;
          })
      );
    } else {
      console.error('Database is not ready');
      return of(null);
    }
  }

  private mapResultToReports(result: any): Report[] {
    const reports: Report[] = [];
    if (result.values) {
      for (let i = 0; i < result.values.length; i++) {
        reports.push({
          id: result.values[i].id,
          name: result.values[i].name,
          reportTypeId: result.values[i].reportTypeId,
          date: result.values[i].date,
          caseFileId: result.values[i].caseFileId,
        });
      }
    }
    return reports;
  }

  addReport(report: Report): Observable<Report> {
    const query = `INSERT INTO Report (id, name, reportTypeId, date, caseFileId)
                   VALUES ('${report.id}', '${report.name}', '${report.reportTypeId}', '${report.date}',
                           '${report.caseFileId}')`;
    const dbName = this.dbService.getDBName();

    return from(
      CapacitorSQLite.execute({
        database: dbName,
        statements: query,
      }).then(() => {
        if (this.dbService.isWeb) {
          CapacitorSQLite.saveToStore({database: dbName});
        }
        return report;
      })
    );
  }

  deleteOne(reportId: string): Observable<void> {
    const query = 'DELETE FROM Report WHERE id = ?';
    const dbName = this.dbService.getDBName();

    return from(
      CapacitorSQLite.query({
        database: dbName,
        statement: query,
        values: [reportId],
      }).then(() => {
        if (this.dbService.isWeb) {
          CapacitorSQLite.saveToStore({database: dbName});
        }
      })
    );
  }

  deleteByCase(caseFileId: string): Observable<void> {
    const query = 'DELETE FROM Report WHERE caseFileId = ?';
    const dbName = this.dbService.getDBName();

    return from(
      CapacitorSQLite.query({
        database: dbName,
        statement: query,
        values: [caseFileId],
      }).then(() => {
        if (this.dbService.isWeb) {
          CapacitorSQLite.saveToStore({database: dbName});
        }
      })
    );
  }
}
