import {Report} from "../../models/report";

export namespace CaseFileActions {
    export class GetOneCaseFile {
        static readonly type = '[CaseFile] Get One CaseFile';

        constructor(public id: string) {}
    }

    export class UpdateCaseFile {
        static readonly type = '[CaseFile] Update CaseFile';

        constructor(public caseFileId: string, public name: string, public description: string) {}
    }

    export class GetAllReports {
        static readonly type = '[CaseFile] Get All Reports';

        constructor(public caseFileId: string) {}
    }

    export class GetOneReport {
        static readonly type = '[CaseFile] Get One Report';

        constructor(public reportId: string) {}
    }

    export class CreateReport {
        static readonly type = '[CaseFile] Create Report';

        constructor(public report: Report) {}
    }

    export class UpdateReport {
        static readonly type = '[CaseFile] Update Report';

        constructor(public report: Report) {}
    }

    export class DeleteReport {
        static readonly type = '[CaseFile] Delete Report';

        constructor(public reportId: string) {}
    }

    export class FetchReportTypesById {
        static readonly type = '[CaseFile] Fetch Report Types By Id';

        constructor(public institutionId: string) {}
    }

    export class GetAllReportTypes {
        static readonly type = '[CaseFile] Get All Report Types';

        constructor() {}
    }

    export class GetOneReportType {
        static readonly type = '[CaseFile] Get One Report Type';

        constructor(public reportTypeId: string) {}
    }
}
