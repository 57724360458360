export namespace InstitutionListActions {
  export class GetAll {
    static readonly type = '[InstitutionList] Get All Institutions';

    constructor() {
    }
  }

  export class GetOne {
    static readonly type = '[InstitutionList] Get One Institution';

    constructor(public institutionId: string) {
    }
  }
}
