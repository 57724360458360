import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Capacitor} from '@capacitor/core';
import {CapacitorSQLite} from '@capacitor-community/sqlite';
import {HttpClient} from '@angular/common/http';

const DB_NAME = 'skeletonMobileDB'; // ¡IMPORTANTE! Al crear la conexión le añade internamente al nombre del archivo el sufijo SQLite.db

@Injectable()
export class DatabaseService {
  public dbReady: BehaviorSubject<boolean>;
  public isWeb: boolean;
  public isIos: boolean;

  private scripts = [
    'assets/scripts/tables.sql',
    'assets/scripts/initialScript.sql',
    'assets/scripts/boneList.sql',
  ];

  constructor(private http: HttpClient) {
    this.dbReady = new BehaviorSubject<boolean>(false);
    this.isWeb = false;
    this.isIos = false;
  }

  async init() {
    const platform = Capacitor.getPlatform();
    const sqlite = CapacitorSQLite as any;

    if (platform === 'android') {
      try {
        await sqlite.requestPermissions();
      } catch (e) {
        console.error('Error requesting permissions!', e);
      }
    } else if (platform === 'web') {
      this.isWeb = true;
      await sqlite.initWebStore();
    } else if (platform === 'ios') {
      this.isIos = true;
    }

    await this.setupDatabase();
  }

  private async setupDatabase() {
    await CapacitorSQLite.createConnection({database: DB_NAME});
    await CapacitorSQLite.open({database: DB_NAME});

    for (const script of this.scripts) {
      await this.executeScript(script);
    }

    this.dbReady.next(true);
  }

  private async executeScript(scriptPath: string) {
    try {
      const script = await this.http.get(scriptPath, {responseType: 'text'}).toPromise() ?? '';
      const statements = script.split(';').filter(query => query.trim() !== '');

      for (const statement of statements) {
        await CapacitorSQLite.execute({
          database: DB_NAME,
          statements: statement,
        });
      }
    } catch (error) {
      console.error('Error executing script:', error);
    }
  }

  getDBName(): string {
    return DB_NAME;
  }
}
