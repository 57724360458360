import {Action, Selector, State, StateContext} from "@ngxs/store";
import {CaseFile} from "../../models/caseFiles";
import {RemoteState} from "../remote-state";
import {Injectable} from "@angular/core";
import {CaseFilesService} from "../../services/case-files.service";
import {CaseFileListActions} from 'src/app/core/store/case-file-list/case-file-list.actions';
import {tap} from "rxjs";

export interface CaseFileListStateModel {
  caseFileList: RemoteState<CaseFile[]>;
  caseFileCreated: RemoteState<CaseFile>;
  caseFileDeleted: RemoteState<CaseFile>;
}

@State<CaseFileListStateModel>({
  name: 'caseFileList',
  defaults: {
    caseFileList: RemoteState.new<CaseFile[]>([]),
    caseFileCreated: RemoteState.new<CaseFile>(),
    caseFileDeleted: RemoteState.new<CaseFile>()
  }
})
@Injectable()
export class CaseFileListState {

  @Selector()
  static caseFileListState(state: CaseFileListStateModel) {
    return state.caseFileList;
  }

  @Selector()
  static caseFileCreatedState(state: CaseFileListStateModel) {
    return state.caseFileCreated;
  }

  constructor(
    private caseFilesService: CaseFilesService
  ) {}

  @Action(CaseFileListActions.GetAll)
  getAll(ctx: StateContext<CaseFileListStateModel>, action: CaseFileListActions.GetAll) {
    const state = ctx.getState();
    const caseFileListRemoteState = state.caseFileList.reset().start();

    return this.caseFilesService.getAll(action.accountId).pipe(
      tap((result: CaseFile[]) => {
        ctx.patchState({
          caseFileList: caseFileListRemoteState.complete(result)
        });
      })
    );
  }

  @Action(CaseFileListActions.Create)
  createOne(ctx: StateContext<CaseFileListStateModel>, action: CaseFileListActions.Create) {
    const state = ctx.getState();
    const caseFileCreatedRemoteState = state.caseFileCreated.reset().start();

    return this.caseFilesService.addCaseFile(action.payload).pipe(
      tap((result: CaseFile) => {
        ctx.patchState({
          caseFileList: state.caseFileList.mutate((oldState) => {
            oldState.push(result);
            return oldState;
          }),
          caseFileCreated: caseFileCreatedRemoteState.complete(result)
        });
      })
    )
  }

  @Action(CaseFileListActions.Delete)
  deleteOne(ctx: StateContext<CaseFileListStateModel>, action: CaseFileListActions.Delete) {
    const state = ctx.getState();
    const caseFileDeletedRemoteState = state.caseFileDeleted.reset().start();

    return this.caseFilesService.deleteOne(action.caseFileId).pipe(
      tap(() => {
        ctx.patchState({
          caseFileList: state.caseFileList.mutate((oldState) => {
            return oldState.filter((caseFile) => caseFile.id !== action.caseFileId);
          }),
          caseFileDeleted: caseFileDeletedRemoteState.complete()
        });
      })
    )
  }
}
