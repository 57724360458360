import type {IStateError} from './state-error';
import {Observable} from "rxjs";

/**
 * Represents the state of a remote resource
 * @variation new - Is a new an unloaded resource
 * @variation loading - Is loading the resource
 * @variation complete - The resource is loaded
 * @variation mutated - The resource is loaded and has been mutated subsequently
 * @variation error - An error occurred while loading the resource
 */
export type RemoteStateStatus = 'new' | 'loading' | 'complete' | 'mutated' | 'error';

/**
 * Represents the state of a remote resource.
 */
export interface IRemoteState<T> {
  /** The current value of the resource */
  state: T;
  /** The current status of the resource */
  status: RemoteStateStatus;
  /** The error that occurred while loading the resource, if any */
  error: IStateError;
}

export abstract class RemoteState<T> implements IRemoteState<T> {
  abstract readonly status: RemoteStateStatus;

  protected constructor(
    protected readonly defaultState: T = null,
    public readonly state: T = null,
    public readonly error: IStateError = null,
  ) {
  }

  /** Returns TRUE if the resource is loading */
  public get loading(): boolean {
    return this.status === 'loading';
  }

  /** Returns TRUE if the resource has loaded */
  public get completed(): boolean {
    return this.status === 'complete' || this.status === 'mutated';
  }

  static new<T>(defaultState: T = null): NewRemoteState<T> {
    return new NewRemoteStateW(defaultState);
  }

  /** Restart the state to default values */
  reset(): NewRemoteState<T> {
    return new NewRemoteStateW<T>(this.defaultState);
  }

  /** Restart to loading state */
  reload(ignoreMutation: boolean = false): LoadingRemoteState<T> {
    if (this.status === 'mutated' && !ignoreMutation) {
      throw new Error('Cannot reload a mutated state unless you specify ignoreMutation flag. The data would be lost.');
    }
    return new LoadingRemoteStateW<T>(this.defaultState, this.state);
  }

  /**
   * Change the resource value
   * @param patch A function that receives the current state and returns the new value
   * */
  mutate(patch: (oldState: T) => T): MutatedRemoteState<T> {
    return new MutatedRemoteState<T>(this.defaultState, patch(this.state));
  }
}

export class NewRemoteState<T> extends RemoteState<T> {
  readonly status: RemoteStateStatus = 'new';

  protected constructor(defaultState: T = null) {
    super(defaultState, defaultState);
  }

  /** Start to load the resource */
  start(): LoadingRemoteState<T> {
    return new LoadingRemoteStateW<T>(this.defaultState, this.state);
  }
}

class NewRemoteStateW<T> extends NewRemoteState<T> {
  constructor(defaultState: T = null) {
    super(defaultState);
  }
}

export class LoadingRemoteState<T> extends RemoteState<T> {
  readonly status: RemoteStateStatus = 'loading';

  protected constructor(defaultState: T, state: T) {
    super(defaultState, state);
  }

  /** Complete the loading of the resource */
  complete(): CompleteRemoteState<T>;

  /**
   * Complete the loading of the resource and set a new state
   * @param newState The new state
   **/
  complete(newState: T): CompleteRemoteState<T>;

  /**
   * Complete the loading of the resource and patch current state
   * @param patch The patch to apply to the current state
   **/
  complete(patch: (state: T) => T): CompleteRemoteState<T>;

  complete(input: T | ((state: T) => T) = null): CompleteRemoteState<T> {
    if (input === null || input === undefined) {
      return new CompleteRemoteStateW<T>(this.defaultState, this.state);
    } else if (typeof input === 'function') {
      const mutator = input as (state: T) => T;
      return new CompleteRemoteStateW<T>(this.defaultState, mutator(this.state));
    } else {
      const newState = input as T;
      return new CompleteRemoteStateW<T>(this.defaultState, newState);
    }
  }

  /** Throw an error while load the resource */
  throw(error: IStateError, resetDefault: boolean = true): ErrorRemoteState<T> {
    return new ErrorRemoteStateW<T>(this.defaultState, resetDefault ? this.defaultState : this.state, error);
  }
}

class LoadingRemoteStateW<T> extends LoadingRemoteState<T> {
  constructor(defaultState: T, state: T) {
    super(defaultState, state);
  }
}

export class CompleteRemoteState<T> extends RemoteState<T> {
  readonly status: RemoteStateStatus = 'complete';

  protected constructor(defaultState: T, state: T) {
    super(defaultState, state);
  }
}

class CompleteRemoteStateW<T> extends CompleteRemoteState<T> {
  constructor(defaultState: T, state: T) {
    super(defaultState, state);
  }
}

export class MutatedRemoteState<T> extends CompleteRemoteStateW<T> {
  override readonly status: RemoteStateStatus = 'mutated';
}

class MutatedRemoteStateW<T> extends CompleteRemoteState<T> {
  override readonly status: RemoteStateStatus = 'mutated';
}

export class ErrorRemoteState<T> extends RemoteState<T> {
  readonly status: RemoteStateStatus = 'error';

  protected constructor(defaultState: T, state: T, error: IStateError) {
    super(defaultState, state, error);
  }
}

class ErrorRemoteStateW<T> extends ErrorRemoteState<T> {
  constructor(defaultState: T, state: T, error: IStateError) {
    super(defaultState, state, error);
  }
}

/**
 * Convert a remote state observable to its value observable. The observable will emit the value when the remote state is complete or mutated, and an error when the remote state is error.
 * @param onlyNext If TRUE, the observable will emit only the next value and complete. If FALSE, the observable will emit all the values from remote states with status complete or mutated.
 */
export function waitRemoteState<T>(onlyNext: boolean = true): (input: Observable<IRemoteState<T>>) => Observable<T> {
  return (input: Observable<IRemoteState<T>>) => {
    let anyHasCompleted = false;
    return new Observable((subscriber) => {
      input.subscribe({
        next: (state) => {
          if (anyHasCompleted && onlyNext) {
            return;
          }
          switch (state.status) {
            case 'complete':
            case 'mutated':
              subscriber.next(state.state);
              if (onlyNext) {
                subscriber.complete();
                anyHasCompleted = true;
              }
              break;
            case 'error':
              subscriber.error(state.error);
              break;
          }
        },
        error: (err) => subscriber.error(err),
        complete: () => subscriber.complete()
      });
    });
  };
}
