import {Injectable} from '@angular/core';
import {Institution} from '../models/report';
import {DatabaseService} from './database.service';
import {from, Observable, of} from 'rxjs';
import {CapacitorSQLite, capSQLiteValues} from '@capacitor-community/sqlite';

@Injectable({
  providedIn: 'root'
})
export class InstitutionService {

  constructor(private dbService: DatabaseService) {
  }

  getAll(): Observable<Institution[]> {
    if (this.dbService.dbReady) {
      const query = 'SELECT * FROM Institution';
      return from(
        CapacitorSQLite.query({
          database: this.dbService.getDBName(),
          statement: query,
          values: [], // Necessary for Android
        })
          .then((result: capSQLiteValues) => {
            // If it's iOS and there are values, remove the first row
            // Because the first row is table information
            if (result.values && this.dbService.isIos && result.values.length > 0) {
              result.values.shift();
            }
            return this.mapResultToInstitutions(result);
          })
          .catch((error) => {
            console.error('Error fetching all institutions:', error);
            return [];
          })
      );
    } else {
      console.error('Database is not ready');
      return of([]);
    }
  }

  getOne(id: string): Observable<Institution | null> {
    if (this.dbService.dbReady) {
      const query = 'SELECT * FROM Institution WHERE id = ?';
      return from(
        CapacitorSQLite.query({
          database: this.dbService.getDBName(),
          statement: query,
          values: [id],
        })
          .then((result: capSQLiteValues) => {
            if (result.values && this.dbService.isIos && result.values.length > 0) {
              result.values.shift();
            }
            return this.mapResultToInstitutions(result)[0];
          })
          .catch((error) => {
            console.error('Error fetching one institution:', error);
            return null;
          })
      );
    } else {
      console.error('Database is not ready');
      return of(null);
    }
  }

  private mapResultToInstitutions(result: any): Institution[] {
    const institutions: Institution[] = [];
    if (result.values && result.values.length > 0) {
      for (let i = 0; i < result.values.length; i++) {
        institutions.push({
          id: result.values[i].id,
          name: result.values[i].name,
          logo: result.values[i].logo,
          alt: result.values[i].alt,
          internalCode: result.values[i].internalCode,
        });
        //console.log('institutions: ', institutions[i]);
      }
    }
    return institutions;
  }
}
