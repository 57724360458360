import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule} from '@angular/router';
import {AuthGuard} from './core/types/authGuard';

const appRoutes = [
  {
    path: 'app',
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./modules/featured-modules/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: '',
    loadChildren: () => import('./modules/featured-modules/login/login.module').then(m => m.LoginPageModule)
  },
  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

