import {CaseFile} from "../../models/caseFiles";

export namespace CaseFileListActions {
    export class GetAll {
        static readonly type = '[CaseFileList] Get All CaseFiles';

        constructor(public accountId: string) {}
    }

    export class Create {
        static readonly type = '[CaseFileList] Create CaseFile';

        constructor(public payload: CaseFile) {}
    }

    export class Delete {
        static readonly type = '[CaseFileList] Delete CaseFile';

        constructor(public caseFileId: string) {}
    }
}
