import {Component, Optional} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {StatusBar, Style} from '@capacitor/status-bar';
import {AlertController, IonRouterOutlet, Platform} from '@ionic/angular';
import {App} from '@capacitor/app';
import {DatabaseService} from './core/services/database.service';
import {Capacitor} from '@capacitor/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  exitHeader: string = '';
  exitMessage: string = '';
  exitConfirm: string = '';
  exitCancel: string = '';
  public isWeb: boolean = false;
  public loading: boolean = false;

  constructor(
    private platform: Platform,
    private translateService: TranslateService,
    public alertController: AlertController,
    private databaseService: DatabaseService,
    @Optional() private routerOutlet?: IonRouterOutlet
  ) {
    translateService.addLangs(['en_GB', 'es_ES']);
    translateService.setDefaultLang('en_GB');
    translateService.use('en_GB');

    this.translateService.stream(['closeApp']).subscribe(translations => {
      this.exitHeader = translations['closeApp'].header;
      this.exitMessage = translations['closeApp'].message;
      this.exitConfirm = translations['closeApp'].exit;
      this.exitCancel = translations['closeApp'].stay;
    });

    this.platform.ready().then(() => {
      if (Capacitor.isNativePlatform()) {
        StatusBar.setBackgroundColor({color: '#FFFFFF'});
        StatusBar.setStyle({style: Style.Light});
        StatusBar.setOverlaysWebView({overlay: false});
      }
    });

    App.addListener('backButton', ({canGoBack}) => {
      if (!canGoBack) {
        this.showExitConfirm();
        // App.exitApp();
      } else {
        window.history.back();
      }
    });

    this.initializeApp();
  }

  showExitConfirm() {
    this.alertController.create({
      header: this.exitHeader,
      message: this.exitMessage,
      backdropDismiss: false,
      buttons: [{
        text: this.exitCancel,
        role: 'cancel'
      }, {
        text: this.exitConfirm,
        handler: () => {
          // navigator['app'].exitApp();
          App.exitApp();
        }
      }]
    })
      .then(alert => {
        alert.present();
      });
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      const info = Capacitor.getPlatform();
      this.isWeb = info === "web";
      this.databaseService.init();
      this.databaseService.dbReady.subscribe(load => {
        this.loading = load;
      });

    });
  }
}
