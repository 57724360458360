import { InstitutionListState } from "./institution-list";
import { CaseFileListState } from './case-file-list/case-file-list.state';
import { CaseFileState } from './case-file/case-file.state';
import { AccountListState } from "./account-list";

export const coreStates = [
  CaseFileListState,
  CaseFileState,
  InstitutionListState,
  AccountListState
];
