import {Account} from "../../models/account";

export namespace AccountListActions {
    export class GetAll {
        static readonly type = '[AccountList] Get All';

        constructor() {}
    }

    export class GetOne {
        static readonly type = '[AccountList] Get One';

        constructor(public loginId: string) {}
    }

    export class Create {
        static readonly type = '[AccountList] Create';

        constructor(public account: Account) {}
    }

    export class Delete {
        static readonly type = '[AccountList] Delete';

        constructor(public loginId: string) {}
    }

    export class Update {
        static readonly type = '[AccountList] Update';

        constructor(public id: string, public name: string, public occupation: string, public profileImage: string) {}
    }
}
