import {Action, Selector, State, StateContext} from "@ngxs/store";
import {Institution} from "../../models/report";
import {RemoteState} from "../remote-state";
import {Injectable} from "@angular/core";
import {
  InstitutionListActions
} from "./institution-list.actions";
import {InstitutionService} from "../../services/institution.service";
import {tap} from "rxjs";

export interface InstitutionListStateModel {
  institutionList: RemoteState<Institution[]>;
  institutionSelected: RemoteState<Institution>;
}

@State<InstitutionListStateModel>({
  name: 'institutionList',
  defaults: {
    institutionList: RemoteState.new<Institution[]>([]),
    institutionSelected: RemoteState.new<Institution>()
  }
})
@Injectable()
export class InstitutionListState {
  @Selector()
  static institutionListState(state: InstitutionListStateModel) {
    return state.institutionList;
  }

  @Selector()
  static institutionState(state: InstitutionListStateModel) {
    return state.institutionSelected;
  }


  constructor(private institutionService: InstitutionService) {
  }

  @Action(InstitutionListActions.GetAll)
  getAll(ctx: StateContext<InstitutionListStateModel>, action: InstitutionListActions.GetAll) {
    const state = ctx.getState();
    const institutionListRemoteState = state.institutionList.reset().start();

    return this.institutionService.getAll().pipe(
      tap((result: Institution[]) => {
        ctx.patchState({
          institutionList: institutionListRemoteState.complete(result)
        });
      })
    );
  }

  @Action(InstitutionListActions.GetOne)
  getOne(ctx: StateContext<InstitutionListStateModel>, action: InstitutionListActions.GetOne) {
    const state = ctx.getState();
    const institutionSelectedRemoteState = state.institutionSelected.reset().start();

    return this.institutionService.getOne(action.institutionId).pipe(
      tap((result: Institution | null) => {
        ctx.patchState({
          institutionSelected: institutionSelectedRemoteState.complete(result)
        });
      })
    );
  }
}
