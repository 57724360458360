import {Injectable} from '@angular/core';
import {CaseFile} from '../models/caseFiles';
import {from, Observable, of, switchMap} from 'rxjs';
import {CapacitorSQLite, capSQLiteValues} from '@capacitor-community/sqlite';
import {DatabaseService} from './database.service';
import {ReportService} from './report.service';


@Injectable({
  providedIn: 'root'
})
export class CaseFilesService {

  constructor(private dbService: DatabaseService, private reportService: ReportService) {
  }


  /**
   * Retrieves all case files based on the provided parameters.
   *
   * @param accountId - The ID of the account associated with the case files.
   * @param caseFileType - Optional. The type of case file to filter by.
   * @param caseFileName - Optional. The name of the case file to filter by.
   * @returns An Observable that emits an array of CaseFile objects.
   */
  getAll(accountId:string , caseFileType?: string, caseFileName?: string): Observable<CaseFile[]> {
    if (this.dbService.dbReady) {
      let query = 'SELECT * FROM CaseFile WHERE createdBy = ?';
      let values = [];
      values.push(accountId);
      if (caseFileType) {
        query += ' AND type = ?';
        values.push(caseFileType);
        if (caseFileName) {
          query += ' AND name LIKE ?';
          values.push(`%${caseFileName}%`);
        }
      }
      return from(
        CapacitorSQLite.query({
          database: this.dbService.getDBName(),
          statement: query,
          values: values,
        })
          .then((result: capSQLiteValues) => {
            // If it's iOS and there are values, remove the first row
            // Because the first row is table information
            if (result.values && this.dbService.isIos && result.values.length > 0) {
              result.values.shift();
            }
            return this.mapResultToCaseFiles(result);
          })
          .catch((error) => {
            console.error('Error fetching all case files:', error);
            return [];
          })
      );
    } else {
      console.error('Database is not ready');
      return of([]);
    }
  }


  /**
   * Adds a case file to the database.
   *
   * @param caseFile - The case file to be added.
   * @returns An Observable that emits the added case file.
   */
  addCaseFile(caseFile: CaseFile): Observable<CaseFile> {
    const query = `INSERT INTO CaseFile (id, name, type, description, createdBy)
                   VALUES ('${caseFile.id}', '${caseFile.name}', '${caseFile.type}', '${caseFile.description}', '${caseFile.createdBy}')`;
    const dbName = this.dbService.getDBName();

    return from(
      CapacitorSQLite.execute({
        database: dbName,
        statements: query,
      }).then(() => {
        if (this.dbService.isWeb) {
          CapacitorSQLite.saveToStore({database: dbName});
        }
        return caseFile;
      })
    );
  }


  /**
   * Maps the result object to an array of CaseFile objects.
   *
   * @param result - The result object to be mapped.
   * @returns An array of CaseFile objects.
   */
  private mapResultToCaseFiles(result: any): CaseFile[] {
    const caseFiles: CaseFile[] = [];
    if (result.values) {
      for (let i = 0; i < result.values.length; i++) {
        caseFiles.push({
          id: result.values[i].id,
          name: result.values[i].name,
          type: result.values[i].type,
          description: result.values[i].description,
          createdBy: result.values[i].createdBy,
        });
        //console.log('Case file: ', caseFiles[i]);
      }
    }
    return caseFiles;
  }


  /**
   * Retrieves a case file based on the provided ID.
   *
   * @param caseFileId - The ID of the case file to retrieve.
   * @returns An Observable that emits the retrieved case file.
   */
  getOne(caseFileId: string): Observable<CaseFile | null> {
    if (this.dbService.dbReady) {
      const query = `SELECT *
                     FROM CaseFile
                     WHERE id = ?`;
      return from(
        CapacitorSQLite.query({
          database: this.dbService.getDBName(),
          statement: query,
          values: [caseFileId],
        })
          .then((result: capSQLiteValues) => {
            if (result.values.length > 0) {
              return this.mapResultToCaseFiles(result)[0];
            } else {
              return null;
            }
          })
          .catch((error) => {
            console.error('Error fetching case file:', error);
            return null;
          })
      );
    } else {
      console.error('Database is not ready');
      return of(null);
    }
  }


  /**
   * Deletes a case file and all associated reports from the database.
   *
   * @param caseFileId - The ID of the case file to delete.
   * @returns An Observable that emits void.
   */
  deleteOne(caseFileId: string): Observable<void> {
    if (this.dbService.dbReady) {
      return this.reportService.deleteByCase(caseFileId).pipe(
        switchMap(() => {
          const query = `DELETE FROM CaseFile WHERE id = ?`;
          return from(
            CapacitorSQLite.query({
              database: this.dbService.getDBName(),
              statement: query,
              values: [caseFileId],
            }).then(() => {
              if (this.dbService.isWeb) {
                CapacitorSQLite.saveToStore({database: this.dbService.getDBName()});
              }
            })
          );
        })
      );
    } else {
      console.error('Database is not ready');
      return of();
    }
  }

  update(id: string, name: string, description: string): Observable<void> {
    const query = `UPDATE CaseFile
                   SET name = ?, description = ?
                   WHERE id = ?`;
    const dbName = this.dbService.getDBName();

    return from(
      CapacitorSQLite.query({
        database: dbName,
        statement: query,
        values: [name, description, id],
      }).then(() => {
        if (this.dbService.isWeb) {
          CapacitorSQLite.saveToStore({database: dbName});
        }
      })
    );
  }
}
