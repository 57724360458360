import {Action, Selector, State, StateContext} from "@ngxs/store";
import {CaseFile} from "../../models/caseFiles";
import {Report, ReportType} from "../../models/report";
import {RemoteState} from "../remote-state";
import {Injectable} from "@angular/core";
import {ReportService} from "../../services/report.service";
import {CaseFilesService} from "../../services/case-files.service";
import {CaseFileActions} from 'src/app/core/store/case-file/case-file.actions';
import {tap} from "rxjs";
import {ReportTypeService} from "../../services/report-type.service";

export interface CaseFileStateModel {
    caseFile: RemoteState<CaseFile>;
    caseFileUpdated: RemoteState<CaseFile>;
    reportList: RemoteState<Report[]>;
    reportSelected: RemoteState<Report>;
    reportCreated: RemoteState<Report>;
    reportUpdated: RemoteState<Report>;
    reportDeleted: RemoteState<Report>;
    fetchReportTypesById: RemoteState<ReportType[]>;
    reportTypeList: RemoteState<ReportType[]>;
    reportTypeSelected: RemoteState<ReportType>;
}

@State<CaseFileStateModel>({
    name: 'caseFile',
    defaults: {
        caseFile: RemoteState.new<CaseFile>(),
        caseFileUpdated: RemoteState.new<CaseFile>(),
        reportList: RemoteState.new<Report[]>([]),
        reportSelected: RemoteState.new<Report>(),
        reportCreated: RemoteState.new<Report>(),
        reportUpdated: RemoteState.new<Report>(),
        reportDeleted: RemoteState.new<Report>(),
        fetchReportTypesById: RemoteState.new<ReportType[]>(),
        reportTypeList: RemoteState.new<ReportType[]>([]),
        reportTypeSelected: RemoteState.new<ReportType>()
    }
})
@Injectable()
export class CaseFileState {
    @Selector()
    static CaseFileState(state: CaseFileStateModel) {
        return state.caseFile;
    }

    @Selector()
    static CaseFileUpdatedState(state: CaseFileStateModel) {
        return state.caseFileUpdated;
    }

    @Selector()
    static ReportListState(state: CaseFileStateModel) {
        return state.reportList;
    }

    @Selector()
    static ReportSelectedState(state: CaseFileStateModel) {
        return state.reportSelected;
    }

    @Selector()
    static FetchReportTypesByIdState(state: CaseFileStateModel) {
        return state.fetchReportTypesById;
    }

    @Selector()
    static ReportTypeListState(state: CaseFileStateModel) {
        return state.reportTypeList;
    }

    @Selector()
    static ReportTypeSelectedState(state: CaseFileStateModel) {
        return state.reportTypeSelected;
    }

    constructor(
        private reportService: ReportService,
        private reportTypeService: ReportTypeService,
        private caseFileService: CaseFilesService
    ) {}

    @Action(CaseFileActions.GetOneCaseFile)
    getOne(ctx: StateContext<CaseFileStateModel>, action: CaseFileActions.GetOneCaseFile) {
        const state = ctx.getState();
        const caseFileRemoteState = state.caseFile.reset().start();

        return this.caseFileService.getOne(action.id).pipe(
            tap((result: CaseFile | null) => {
                ctx.patchState({
                    caseFile: caseFileRemoteState.complete(result)
                });
            })
        );
    }

    @Action(CaseFileActions.UpdateCaseFile)
    updateOne(ctx: StateContext<CaseFileStateModel>, action: CaseFileActions.UpdateCaseFile) {
        const state = ctx.getState();
        const caseFileUpdatedRemoteState = state.caseFileUpdated.reset().start();

        return this.caseFileService.update(action.caseFileId, action.name, action.description).pipe(
            tap(() => {
                ctx.patchState({
                    caseFileUpdated: caseFileUpdatedRemoteState.complete()
                });
            })
        );
    }

    @Action(CaseFileActions.GetAllReports)
    getAllReports(ctx: StateContext<CaseFileStateModel>, action: CaseFileActions.GetAllReports) {
        const state = ctx.getState();
        const reportListRemoteState = state.reportList.reset().start();

        return this.reportService.getAll(action.caseFileId).pipe(
            tap((result: Report[]) => {
                ctx.patchState({
                    reportList: reportListRemoteState.complete(result)
                });
            })
        );
    }

    @Action(CaseFileActions.GetOneReport)
    getOneReport(ctx: StateContext<CaseFileStateModel>, action: CaseFileActions.GetOneReport) {
        const state = ctx.getState();
        const reportSelectedRemoteState = state.reportSelected.reset().start();

        return this.reportService.getOne(action.reportId).pipe(
            tap((result: Report | null) => {
                ctx.patchState({
                    reportSelected: reportSelectedRemoteState.complete(result)
                });
            })
        );
    }

    @Action(CaseFileActions.CreateReport)
    createOneReport(ctx: StateContext<CaseFileStateModel>, action: CaseFileActions.CreateReport) {
        const state = ctx.getState();
        const reportCreatedRemoteState = state.reportCreated.reset().start();

        return this.reportService.addReport(action.report).pipe(
            tap((result: Report) => {
                ctx.patchState({
                    reportList: state.reportList.mutate((oldState) => {
                        oldState.push(result);
                        return oldState;
                    }),
                    reportCreated: reportCreatedRemoteState.complete(result)
                });
            })
        )
    }

    @Action(CaseFileActions.UpdateReport)
    updateOneReport(ctx: StateContext<CaseFileStateModel>, action: CaseFileActions.UpdateReport) {
        const state = ctx.getState();
        const reportUpdatedRemoteState = state.reportUpdated.reset().start();

        //TODO: Implemented action to update a report once the function for updating reports in the service has been implemented.
    }

    @Action(CaseFileActions.DeleteReport)
    deleteOneReport(ctx: StateContext<CaseFileStateModel>, action: CaseFileActions.DeleteReport) {
        const state = ctx.getState();
        const reportDeletedRemoteState = state.reportDeleted.reset().start();

        return this.reportService.deleteOne(action.reportId).pipe(
            tap(() => {
                ctx.patchState({
                    reportList: state.reportList.mutate((oldState) => {
                        return oldState.filter((report) => report.id !== action.reportId);
                    }),
                    reportDeleted: reportDeletedRemoteState.complete()
                });
            })
        )
    }

    @Action(CaseFileActions.FetchReportTypesById)
    fetchReportTypesById(ctx: StateContext<CaseFileStateModel>, action: CaseFileActions.FetchReportTypesById) {
        const state = ctx.getState();
        const reportTypeListRemoteState = state.reportTypeList.reset().start();

        return this.reportTypeService.fetchReportTypesById(action.institutionId).pipe(
            tap((result: ReportType[]) => {
                ctx.patchState({
                    fetchReportTypesById: reportTypeListRemoteState.complete(result)
                });
            })
        );
    }

    @Action(CaseFileActions.GetAllReportTypes)
    getAllReportTypes(ctx: StateContext<CaseFileStateModel>, action: CaseFileActions.GetAllReportTypes) {
        const state = ctx.getState();
        const reportTypeListRemoteState = state.reportTypeList.reset().start();

        return this.reportTypeService.getAll().pipe(
            tap((result: ReportType[]) => {
                ctx.patchState({
                    reportTypeList: reportTypeListRemoteState.complete(result)
                });
            })
        );
    }

    @Action(CaseFileActions.GetOneReportType)
    getOneReportType(ctx: StateContext<CaseFileStateModel>, action: CaseFileActions.GetOneReportType) {
        const state = ctx.getState();
        const reportTypeSelectedRemoteState = state.reportTypeSelected.reset().start();

        return this.reportTypeService.getOne(action.reportTypeId).pipe(
            tap((result: ReportType | null) => {
                ctx.patchState({
                    reportTypeSelected: reportTypeSelectedRemoteState.complete(result)
                });
            })
        );
    }
}

